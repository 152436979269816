<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'MainView',
  }
</script>

<style lang="scss" scoped>
.v-main {
  padding: 0 !important;
}
</style>
